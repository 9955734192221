

















































import { Component, Vue } from 'vue-property-decorator';
import ChevronDoubleUpIcon from 'vue-material-design-icons/ChevronDoubleUp.vue';

@Component({
  components: { ChevronDoubleUpIcon },
})
export default class ResumoToggle extends Vue {
  public showInfo = false;

  get enableSidebar() {
    return this.$store.getters.show_sidebar;
  }

  get valorMensal() {
    return this.$store.getters.valor_mensalidade;
  }

  get valorCortado() {
    return this.$store.getters.valor_cortado;
  }

  get valorAnual() {
    return this.$store.getters.valor_anual;
  }

  get valorAdesao() {
    return this.$store.state.cotacao.valor_adesao;
  }

  public showSidebar() {
    this.showInfo = !this.showInfo;
  }
}
