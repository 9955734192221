

















import {Component, Vue} from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import DetalhesSidebar from '@/components/detalhes/DetalhesSidebar.vue';
import BackgroundImage from '@/components/BackgroundImage.vue';
import ResumoToggle from '@/components/ResumoToggle.vue';

@Component({
  components: {
    ResumoToggle,
    BackgroundImage,
    DetalhesSidebar,
    PabHeader: Header,
    PabFooter: Footer,
  },
})
export default class App extends Vue {
  public mounted() {
    document.title = 'Simulador Proteauto Brasil';
    // Muda titulo da página quando muda de rota
    this.$router.beforeEach((to, from, next) => {
      document.title = to.meta.title;
      next();
    });
    // Rola pro topo quando muda de rota
    this.$router.afterEach((to, next) => {
      window.scrollTo(0, 0);
    });

    try {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    } catch (e) {
    }
  }
}
