import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dados',
      props: (route) => ({ ...route.query }),
      meta: { title: 'Home', backButton: false },
      component: () => import(/* webpackChunkName: "dados" */ '@/views/Dados.vue'),
    },
    {
      path: '/simule',
      name: 'cliente',
      props: (route) => ({ ...route.query }),
      meta: { title: 'Home', backButton: false },
      component: () => import(/* webpackChunkName: "cliente" */ '@/views/DadosCliente.vue'),
    },
    {
      path: '/veiculo',
      name: 'veiculo',
      meta: { title: 'Veículo' },
      component: () => import(/* webpackChunkName: "veiculo" */ '@/views/DadosVeiculo.vue'),
    },
    {
      path: '/detalhes/:arg',
      name: 'detalhes',
      meta: { title: 'Proposta' },
      component: () => import(/* webpackChunkName: "detalhes" */ '@/views/Detalhes.vue'),
    },
    {
      path: '/resumo/:arg',
      name: 'resumo',
      meta: { title: 'Resumo' },
      component: () => import(/* webpackChunkName: "resumo" */ '@/views/Resumo.vue'),
    },
    {
      path: '/proposta',
      name: 'proposta',
      props: (route) => ({ ...route.query }),
      meta: { title: 'Proposta', backButton: false  },
      component: () => import(/* webpackChunkName: "proposta" */ '@/views/Proposta.vue'),
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
