import axios from 'axios';
import getCookie from 'js-cookie';

export default class RdStation {
  public static async conversion(data: {}) {
    // Remove todos os ( "", 0, false, null, undefined )
    data = Object.entries(data).reduce((a: any, [k, v]) => {
      return (v ? (a[k] = v, a) : a);
    }, {});

    const response = await RdStation.instance
      .post('/conversions', { ...RdStation.getData(), ...data });
    return response.data;
  }

  private static instance = axios.create({
    baseURL: 'https://www.rdstation.com.br/api/1.3',
  });

  private static firstConversion = true;

  private static getData() {
    let data: any = {
      token_rdstation: 'e3e4df45640b45e8ca60d87fe7c509a5',
    };
    if (RdStation.firstConversion) {
      RdStation.firstConversion = false;
      data = {
        ...data,
        traffic_source: getCookie.get('__trf.src'),
        c_utmz: getCookie.get('__utmz'),
        client_id: getCookie.get('rdtrk'),
      };
    }
    return data;
  }
}
