






























































import { Component, Vue } from 'vue-property-decorator';
import WhatsappIcon from 'vue-material-design-icons/Whatsapp.vue';
import RdStation from '@/plugins/api/RdStation';

@Component({
  components: { WhatsappIcon },
})
export default class DetalhesSidebar extends Vue {
  get showSidebar() {
    return this.$store.getters.show_sidebar;
  }

  get valorMensal() {
    return this.$store.getters.valor_mensalidade;
  }

  get valorCortado() {
    return this.$store.getters.valor_cortado;
  }

  get valorAnual() {
    return this.$store.getters.valor_anual;
  }

  get valorAdesao() {
    return this.$store.state.cotacao.valor_adesao;
  }
  // noinspection JSMethodCanBeStatic
  public get year() {
    return new Date().getFullYear();
  }

  public openWhatsApp() {
    let url = 'https://bit.ly/3RBYT9b';

    if (this.$store.state.vendedor && this.$store.state.vendedor.link) {
      url = this.$store.state.vendedor.link;
    }

    window.open(url, '_blank');
    RdStation.conversion({
      'email': this.$store.getters.get_email,
      'identificador': 'Clicou em Whatsapp',
      'Clicou em Whatsapp': 'Sim',
    }).then();
  }
}
