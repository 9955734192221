













import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BackgroundImage extends Vue {
  public showBackground = false;

  public mounted() {
    if (this.$router) {
      this.$router.afterEach((to) => {
        this.showBackground = ['dados', 'cliente'].includes(to.name!);
      });
    }
  }
}
