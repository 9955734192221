


































import { Component, Vue } from 'vue-property-decorator';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft.vue';

@Component({
  components: { ArrowLeftIcon },
})
export default class Header extends Vue {
  public showBackButton = false;

  public mounted() {
    this.$router.afterEach((to) => {
      this.showBackButton = to.meta.backButton ?? true;
    });
  }
}
